import { Nullable } from "@core/domain/types/nullable.type";
import {
    CreateDepositDto,
    DepositDto,
    SplitDepositDto,
} from "@entity/data/dto/deposit.dto";
import {
    DepositAnotherSourcesFinancingTypeEnum,
    DepositOwnFinancingTypeEnum,
    DepositSubventionTypeEnum,
} from "@entity/domain/models/deposit-type.model";
import {
    CreateDeposit,
    Deposit,
    SplitDeposit,
} from "@entity/domain/models/deposit.model";
import { IdentificationTypeEnum } from "@entity/domain/models/identification-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { IdentificationEnumMapper } from "./identification-enum.mapper";

@injectable()
export class DepositMapper {
    constructor(
        @inject(IdentificationEnumMapper)
        private readonly identificationEnumMapper: IdentificationEnumMapper,
    ) {}
    map(depositDto: DepositDto): Nullable<Deposit> {
        const errors = validateSync(depositDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        let identificationType = null;

        if (depositDto.identification_type) {
            identificationType = this.identificationEnumMapper.map(
                depositDto.identification_type,
            );
        }

        const splitDepositData =
            depositDto.split_deposit_data?.map(
                // eslint-disable-next-line arrow-body-style
                (splitDeposit) => {
                    return new SplitDeposit(
                        splitDeposit.id,
                        splitDeposit.deposit,
                        splitDeposit.project,
                        splitDeposit.assigned_percentage,
                    );
                },
            ) ?? [];

        return new Deposit(
            depositDto.id,
            depositDto.concept,
            depositDto.type,
            depositDto.own_financing_type
                ? depositDto.own_financing_type
                : null,
            depositDto.subvention_type ? depositDto.subvention_type : null,
            depositDto.others_source_financing_type
                ? depositDto.others_source_financing_type
                : null,
            depositDto.income_linked_another_project ?? null,
            depositDto.amount,
            depositDto.name ?? "",
            depositDto.deposit_date,
            identificationType ?? IdentificationTypeEnum.dni,
            depositDto.identification,
            depositDto.street,
            depositDto.zip_code,
            depositDto.town,
            depositDto.province,
            depositDto.grants ?? "",
            depositDto.call_year ?? "",
            depositDto.execution_year ?? "",
            depositDto.call_status ?? "",
            depositDto.entity,
            depositDto.projects_data ? depositDto.projects_data[0] : undefined,
            depositDto.document ?? undefined,
            depositDto.acquisition_date ?? "",
            depositDto.others_type ?? undefined,
            depositDto.observations ?? undefined,
            depositDto.document_data?.name,
            splitDepositData,
        );
    }

    splitDepositMap(splitDepositDto: SplitDepositDto): Nullable<SplitDeposit> {
        const errors = validateSync(splitDepositDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        return new SplitDeposit(
            splitDepositDto.id,
            splitDepositDto.deposit,
            splitDepositDto.project,
            splitDepositDto.assigned_percentage,
        );
    }

    mapToDepositDto(deposit: Deposit): DepositDto {
        const depositDto = new DepositDto();

        let identificationType = null;
        if (deposit.identificationType) {
            identificationType = this.identificationEnumMapper.mapToDto(
                deposit.identificationType,
            );
        }

        depositDto.id = deposit.id;
        depositDto.concept = deposit.concept;
        depositDto.type = deposit.type;
        depositDto.own_financing_type =
            deposit.ownFinancingType ?? DepositOwnFinancingTypeEnum[""];
        depositDto.subvention_type =
            deposit.subventionType ?? DepositSubventionTypeEnum[""];
        depositDto.others_source_financing_type =
            deposit.othersSourceFinancingType ??
            DepositAnotherSourcesFinancingTypeEnum[""];
        depositDto.amount = deposit.amount;
        depositDto.deposit_date = deposit.depositDate;
        depositDto.entity = deposit.entity;

        depositDto.street = deposit.street;
        depositDto.grants = deposit.grants;

        if (deposit.callYear) {
            depositDto.call_year = deposit.callYear;
        }

        if (deposit.executionYear) {
            depositDto.execution_year = deposit.executionYear;
        }

        if (deposit.callStatus) {
            depositDto.call_status = deposit.callStatus;
        }

        if (deposit.acquisitionDate) {
            depositDto.acquisition_date = deposit.acquisitionDate;
        }

        if (deposit.othersType) {
            depositDto.others_type = deposit.othersType;
        }

        if (deposit.observations) {
            depositDto.observations = deposit.observations;
        }

        if (deposit.relatedProject) {
            depositDto.projects_data = [deposit.relatedProject];
        } else {
            depositDto.name = deposit.name;
            depositDto.identification_type = identificationType;
            depositDto.identification = deposit.identification;
            depositDto.zip_code = deposit.zipCode;
            depositDto.town = deposit.town;
            depositDto.province = deposit.province;
        }

        if (deposit.relatedDocument) {
            depositDto.document = deposit.relatedDocument;
        }

        return depositDto;
    }

    mapToCreateDepositDto(deposit: CreateDeposit): CreateDepositDto {
        const depositDto = new CreateDepositDto();

        let identificationType = null;

        if (deposit.identificationType) {
            identificationType = this.identificationEnumMapper.mapToDto(
                deposit.identificationType,
            );
        }

        depositDto.concept = deposit.concept;
        depositDto.type = deposit.type;
        depositDto.own_financing_type =
            deposit.ownFinancingType ?? DepositOwnFinancingTypeEnum[""];
        depositDto.subvention_type =
            deposit.subventionType ?? DepositSubventionTypeEnum[""];
        depositDto.others_source_financing_type =
            deposit.othersSourceFinancingType ??
            DepositAnotherSourcesFinancingTypeEnum[""];
        depositDto.amount = deposit.amount;
        depositDto.deposit_date = deposit.depositDate;
        depositDto.entity = deposit.entity;

        depositDto.street = deposit.street;
        depositDto.grants = deposit.grants;

        if (deposit.callYear) {
            depositDto.call_year = deposit.callYear;
        }

        if (deposit.executionYear) {
            depositDto.execution_year = deposit.executionYear;
        }

        if (deposit.callStatus) {
            depositDto.call_status = deposit.callStatus;
        }

        if (deposit.acquisitionDate) {
            depositDto.acquisition_date = deposit.acquisitionDate;
        }

        if (deposit.othersType) {
            depositDto.others_type = deposit.othersType;
        }

        if (deposit.observations) {
            depositDto.observations = deposit.observations;
        }

        if (deposit.relatedProject) {
            depositDto.projects_data = [deposit.relatedProject];
        } else {
            depositDto.name = deposit.name;
            depositDto.identification_type = identificationType;
            depositDto.identification = deposit.identification;
            depositDto.zip_code = deposit.zipCode;
            depositDto.town = deposit.town;
            depositDto.province = deposit.province;
        }

        if (deposit.relatedDocument) {
            depositDto.document = deposit.relatedDocument;
        }

        return depositDto;
    }
}
